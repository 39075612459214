/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PageNotFound from './components/PageNotFound';
import Version from './components/Version';
import FaceVerify from './screens/FaceVerify';
import i18n from './Translations';
import { initReactI18next } from 'react-i18next';
import { resources } from './Translations/resources';
import LanguageDetector from 'i18next-browser-languagedetector'

/**
 * The main application component that initializes the i18n configuration and renders the app routes.
 *
 * @return {JSX.Element} The JSX element representing the app routes.
 */

function App() {

  i18n.use(initReactI18next).use(LanguageDetector).init({
    resources,
    lng: 'en',
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route path="/faceverify" element={<FaceVerify />} />
        <Route path="/version" element={<Version />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
