/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */
import { useEffect, useState } from "react"
import { Config } from "../Config";
import { useLocation } from "react-router-dom";

/**
 * A custom React hook to check the face verification status of a user.
 * 
 * It extracts the encrypted user ID and IP address from the URL query parameters,
 * makes a GET request to the API to verify the user's face, and updates the
 * `isFaceVerificationCompleted` state accordingly.
 * 
 * @return {object} An object containing the `isFaceVerificationCompleted` state.
 */

export const useFaceVerificationCheck = () => {
    const query = new URLSearchParams(useLocation().search);
    const cUserIdEncrypted = query.get("cUserId");
    const ipAddressEncrypted = query.get("cIPAddress");
    const apiUrl = Config.API_URL;
    const [isFaceVerificationCompleted, setIsFaceVerificationCompleted] = useState(false)

    useEffect(() => {
        console.log("face verification check");
        console.log("cUserIdEncrypted", cUserIdEncrypted);
        console.log("ipAddressEncrypted", ipAddressEncrypted);

        fetch(`${apiUrl}/api/api/users/verification/${cUserIdEncrypted}/${ipAddressEncrypted}`, { method: "GET" })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                console.log("Face verification response:", data);
                setIsFaceVerificationCompleted(data.isFaceVerified);
            })
            .catch((error) => {
                console.error("Error fetching face verification:", error);
            });
    }, [cUserIdEncrypted, ipAddressEncrypted, apiUrl]);

    return {
        isFaceVerificationCompleted
    };
};