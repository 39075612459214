/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */


/**
 * A React functional component that renders a page not found message.
 *
 * @return {JSX.Element} The JSX element representing the page not found message.
 */

function PageNotFound() {
    return (
        <div className="flex items-center justify-center h-screen bg-white">
            <div className='flex flex-col gap-y-3 text-center items-center'>
                <span className='text-xl md:text-3xl text-gray-500 font-normal'>404 | Page Not Found</span>
            </div>

        </div>
    )
}

export default PageNotFound