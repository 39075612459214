/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */


/**
 * Renders a component displaying the web version.
 *
 * @return {JSX.Element} The rendered component.
 */

function Version() {
    return (
        <div className="flex items-center justify-center h-screen bg-white">
            <div className='flex flex-col gap-y-3 text-center items-center'>
                <div><span className="font-light">Web version:</span> <span className="font-semibold">{process.env.REACT_APP_VERSION}</span></div>
            </div>

        </div>
    )
}

export default Version