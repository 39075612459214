/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */


/**
 * A functional component that displays a loading animation.
 *
 * @return {JSX.Element} The JSX element representing the loading animation.
 */

function Loading() {
    return (
        <div className="flex justify-center" id="load_data" data-testid="load_data">
            <img src={require('../Asset/beemg_logo.gif')} alt="loading..." className="h-24 w-24" style={{ objectFit: 'contain' }} />
        </div>
    )
}

export default Loading;