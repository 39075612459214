import en from './en';
import ja from './ja';

export const resources ={
    en: {
        translation: en
    },
    ja: {
        translation: ja
    }
}
