// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "AndOpenYourMouthToStart": "そして口を開けて始めましょう",
    "down": "下",
    "faceDownVerification": "フェイスダウン認証",
    "faceUpVerification": "顔アップ認証",
    "faceVerification": "顔認証",
    "faceVerificationAlreadyCompletedForThisUser": "このユーザーの顔認証はすでに完了しています",
    "faceVerificationCompleted": "顔認証完了",
    "faceVerificationFailed": "顔認証に失敗しました",
    "faceVerificationStatus": "顔認証ステータス",
    "Failed": "失敗した",
    "IN_VERIFICATION": "検証中",
    "left": "左",
    "leftFaceVerification": "左顔認証",
    "multipleFacesDetected": "複数の顔が検出されました",
    "noFaceDetectedVerificationFailed": "顔が検出されませんでした。検証に失敗しました",
    "NOT_STARTED": "始まっていない",
    "pleaseAlignYourFaceInsideTheCircle": "顔を円の中に合わせてください",
    "pleaseAlignYourFaceInsideTheCircleAndTryAgain": "顔を円の中に合わせてもう一度お試しください",
    "pleaseAllowCameraPermissionForVerifyingYourFace": "顔を認証するためにカメラの許可を許可してください",
    "pleaseBlinkBothYourEyes": "両目を瞬きしてください",
    "pleaseBlinkBothYourEyesToStart": "両目を瞬きして始めてください",
    "pleaseEnsureThatOnlyOnePersonIsInTheCameraFrameAndTryAgain": "カメラのフレーム内に 1 人だけがいることを確認して、もう一度お試しください。",
    "pleaseFollowTheArrows": "矢印に従って進んでください",
    "lookAtCameraStraightAndTurnSlowly": "カメラをまっすぐ見て、ゆっくりと向きを変えてください",
    "pleaseLookDownSlowly": "ゆっくり下を向いてください",
    "pleaseLookUpSlowly": "ゆっくり見上げてください",
    "pleaseTurnSlowlyTowardsYourLeft": "ゆっくりと左に曲がってください",
    "pleaseTurnSlowlyTowardsYourRight": "ゆっくりと右に曲がってください",
    "pleaseTurnSlowlyAndSlightly": "ゆっくりと少し回してください",
    "redirectingToTheNextStep": "次のステップにリダイレクトします",
    "retry": "リトライ",
    "right": "右",
    "rightFaceVerification": "右顔認証",
    "straight": "真っ直ぐ",
    "straightFaceVerification": "真顔認証",
    "up": "上",
    "VERIFIED": "確認済み",
    "verificationCompleted": "検証完了",
    "verificationFailed": "検証に失敗しました",
  };